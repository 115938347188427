.tile {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 12px;
    border: 1px solid transparent;
    padding: 50px 15px;
    background-color: #FFFFFF;
    align-items: center;
    margin-bottom: 40px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    position: relative;
    &__title {
        font-size: 16px;
        line-height: 20px;
        font-family: 'Speedee-Bd';
        height: 40px;
    }
    &__image {
        margin-bottom: 25px;
        max-width: 100%;
        max-height: 100px;
    }
    &__icon {
        position: absolute;
        left: 15px;
        top: 15px;
    }
}

.tile-ar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    model-viewer {
        width: 800px;
        height: 600px;
        @media screen and (max-width: 767px) {
            width: 90%;
            height: 350px;
            margin: 0 auto;
        }
    }
}

.tile-ar__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
}

.close-ar-modal {
    background: #333;
    padding: 15px;
    border-radius: 50%;
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
}