header {
    padding: 15px 0;
    background: transparent;
    position: fixed;
    width: 100%;
    z-index: 9;
    transition: all 300ms ease-in-out;
    &.is_stuck {
        background:#fff;
        box-shadow: 2px 3px 4px 3px #0000001a;
    }
    .container {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    a {
        font-style: normal;
        font-weight: bold;
        font-family: Speedee-Bd;
        font-size: 18px;
        line-height: 22px;
        color: #292929;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
            color: #292929;
        }
    }

    img {
        cursor: pointer;
        transition: all 300ms ease-in-out;
        &:hover {
            transform: scale(1.2);
        }
    }
}

.full-menu {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background: #292929;
    z-index: 11;
    header {
        position: unset;
        a {
            color: #fff;
        }
    }
}

.navigation {

    .container {
        display: flex;
        justify-content: space-between;
    }

    nav {
        margin: 50px 0 0;
        li {
            padding: 15px 0;
            a {
                color: #FFBC0D;
                font-size: 35px;
                line-height: 42px;
                text-decoration: none;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 2px;
                    background: #FFBC0D;
                    transition: all 600ms ease-in-out;
                    bottom: 0;
                    left: 0;
                }
                &:hover {
                    &:after {
                        width: 100%;
                    }   
                }
            }
        }
    }
    .header-social-icons {
        display: flex;
        align-items: end;
        padding-bottom: 50px;
        a {
            display: inline-block;
            margin-right: 15px;
            overflow: hidden;
            border-radius: 50%;
            &:last-of-type {
                margin-right: 0;
            }
            img {
                transition: all 300ms ease-in-out;
            }
            &:hover {
                img {
                    transform: scale(1.2);
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        .container {
            flex-direction: column;
        }
        nav {
            margin: 25px 0 0;
            ul {
                padding: 0 25px;
                li {
                    a {
                        font-size: 22px;
                        line-height: 22px;
                    }
                }
            }
        }
        .header-social-icons {
            padding-bottom: 0;
            padding-top: 15px;
            justify-content: center;
        }
    }
}