// // stylelint-disable selector-no-qualifying-type

// //
// // Base styles
// //

// @import "~bootstrap/scss/bootstrap";

.btn {
    display: inline-block;
    // font-family: $btn-font-family;
    // font-weight: $btn-font-weight;
    // color: $mcd-black;
    text-align: center;
    text-decoration: none;
    // white-space: $btn-white-space;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 4px;
    padding: 15px;

    font-size: 16px;
    font-weight: 14px;
    &:hover {

    }

    &--yellow {
      background-color: #FFBC0D;
      &:hover {
        background-color: transparent;
        border: 2px solid#FFBC0D;
      }
    }

  }
  
